import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { CustomEntitiesListingModel, CustomEntitiesSearchFiltersModel, Meta } from '../../models/models';
import { DATA_QA } from '../../helpers/data-qa.properties';
import CustomEntitiesHttpService from '../../../../../services/rest/custom-entities.http.service';
import { normalizeCustomEntitiesListing } from '../../models/mappers';
import { CustomEntitiesTableListing } from './custom-entities-table-listing.component';
import { AppState } from '../../../../../store/store';
import { toast } from 'react-toastify';
import {
	returnObjectForCustomEntitiesFiltersUpdate,
	returnObjectForCustomEntitiesPaginationUpdate,
} from '../../../../../store/action-creators/custom-entities-action-creator';

type Properties = {
	pagination: Meta;
	customEntitiesFilters: CustomEntitiesSearchFiltersModel;
	customEntitiesFiltersUpdate: (filters: CustomEntitiesSearchFiltersModel) => void;
	customEntitiesPaginationUpdate: (pagination: Meta) => void;
	customEntities: CustomEntitiesListingModel;
};

const CustomEntitiesListing: FunctionComponent<Properties> = ({
	pagination,
	customEntitiesFilters,
	customEntitiesFiltersUpdate,
	customEntitiesPaginationUpdate,
}) => {
	const [t] = useTranslation();
	const [pageOffset, setPageOffset] = useState((pagination && pagination.offset) || 0);
	const [customEntities, setCustomEntities] = useState({} as CustomEntitiesListingModel);

	useEffect(() => {
		initPageTitle();
	}, []);

	const initPageTitle = () => {
		document.title = t('custom_entities');
	};

	const getEntities = (searchQuery: string, offset: number, limit: number) => {
		CustomEntitiesHttpService.getCustomEntities(searchQuery, offset, limit)
			.then((response) => {
				const data = normalizeCustomEntitiesListing(response.data);
				setCustomEntities(data ? data : ({} as CustomEntitiesListingModel));
			})
			.catch(() => toast.error(t('error_fetching_data')));
	};

	return (
		<div className='animated fadeIn' data-qa={DATA_QA.CUSTOM_ENTITIES_LISTING}>
			<div className='custom-entities-listing-card'>
				<div className='listing-header d-flex bg-white'>
					<div className='header-text'>
						<span className='d-flex'>
							<strong>{t('custom_entities')}</strong>
						</span>
					</div>
				</div>
				<CustomEntitiesTableListing
					content={customEntities}
					getContent={getEntities}
					contentPagination={pagination}
					filters={customEntitiesFilters}
					currentOffset={pageOffset}
					updateContentFilters={customEntitiesFiltersUpdate}
					updateContentPagination={customEntitiesPaginationUpdate}
					setCurrentOffset={setPageOffset}
				/>
			</div>
		</div>
	);
};

function mapStateToProps(state: AppState) {
	return {
		customEntitiesFilters: state.customEntities.filters,
		pagination: state.customEntities.pagination,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		customEntitiesFiltersUpdate: (filters: CustomEntitiesSearchFiltersModel) => dispatch(returnObjectForCustomEntitiesFiltersUpdate(filters)),
		customEntitiesPaginationUpdate: (pagination: Meta) => dispatch(returnObjectForCustomEntitiesPaginationUpdate(pagination)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(CustomEntitiesListing);
