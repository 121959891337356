import * as React from 'react';
import { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import Select from 'react-select';
import { CustomEntitiesSearchFiltersModel, Domain } from '../../../models/models';
import { useTranslation } from 'react-i18next';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { customEntitiesAdvancedFiltersStyles, domainsToOptions, domainToOption } from '../../../helpers/custom-entities.helper';
import CustomEntitiesHttpService from '../../../../../../services/rest/custom-entities.http.service';
import '../../../styles/custom-entities-listing.style.scss';

type Properties = {
	updateCustomEntitiesAdvancedSearchText: (customEntitiesAdvancedFiltersModel: CustomEntitiesSearchFiltersModel) => void;
	customEntitiesAdvancedFiltersModel: CustomEntitiesSearchFiltersModel;
};

export const CustomEntitiesDomainSelect: React.FunctionComponent<Properties> = ({
	updateCustomEntitiesAdvancedSearchText,
	customEntitiesAdvancedFiltersModel,
}) => {
	const [domains, setDomains] = useState<Domain[]>([]);
	const [t] = useTranslation();

	useEffect(() => {
		CustomEntitiesHttpService.getDomains().then((response) => {
			response.data && setDomains(response.data.results);
		});
	}, []);

	const onDomainSelect = (domain: SelectMenuOptionType) => {
		if (domain) {
			let advancedContentModel = customEntitiesAdvancedFiltersModel;
			advancedContentModel.domain = domain.data;
			updateCustomEntitiesAdvancedSearchText(advancedContentModel);
		} else if (!domain) {
			let advancedContentModel = customEntitiesAdvancedFiltersModel;
			advancedContentModel.domain = {} as Domain;
			updateCustomEntitiesAdvancedSearchText(advancedContentModel);
		}
	};

	const selectedValuePresent =
		customEntitiesAdvancedFiltersModel.domain &&
		customEntitiesAdvancedFiltersModel.domain.id &&
		customEntitiesAdvancedFiltersModel.domain.id.length > 0
			? customEntitiesAdvancedFiltersModel.domain
			: null;

	return (
		<div data-qa={DATA_QA.CUSTOM_ENTITIES_DOMAIN_SELECT}>
			<Label className='custom-entities-advanced-search-labels' htmlFor={DATA_QA.CUSTOM_ENTITIES_DOMAIN_SELECT}>
				{t('domain_filter')}
			</Label>
			{
				// @ts-ignore
				<Select
					isClearable={customEntitiesAdvancedFiltersModel.domain && customEntitiesAdvancedFiltersModel.domain.id}
					styles={customEntitiesAdvancedFiltersStyles}
					id={DATA_QA.CUSTOM_ENTITIES_DOMAIN_SELECT}
					options={domainsToOptions(domains)}
					value={domainToOption(selectedValuePresent)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={onDomainSelect}
				/>
			}
		</div>
	);
};
