import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import SelectInputDesign from '../../../../../Partials/design-components/select/select';
import InputDesign from '../../../../../Partials/design-components/input/input';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { extractContentModeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { updateCustomEntity } from '../../../../../../store/action-creators/custom-entities-action-creator';
import { CustomEntitiesTypes } from '../../../helpers/custom-entities.helper';
import CustomEntitiesHttpService from '../../../../../../services/rest/custom-entities.http.service';
import { CustomEntitiesSelectOption } from '../../../models/models';

type Props = {
	reduxRoles: any[];
	customEntityDomainId: string;
	updateCustomEntity: (dataForUpdate: Record<string, unknown>) => void;
};

const CustomEntityRoles: FunctionComponent<Props> = ({ reduxRoles, customEntityDomainId, updateCustomEntity }) => {
	const { t } = useTranslation();
	const [roleSelect, setRoleSelect] = useState<CustomEntitiesSelectOption[]>([]);
	const [organizationSelect, setOrganizationSelect] = useState<CustomEntitiesSelectOption[]>([]);
	const [placeSelect, setPlaceSelect] = useState<CustomEntitiesSelectOption[]>([]);

	useEffect(() => {
		Promise.all([
			CustomEntitiesHttpService.getRoles(),
			CustomEntitiesHttpService.getOrganizations(customEntityDomainId),
			CustomEntitiesHttpService.getPlaces(customEntityDomainId),
		])
			.then((response) => response.map((res) => res.data.results))
			.then((data) => {
				data.forEach((item, index) => {
					const requestTypeFunc = getRequestTypeFunction(index);
					const mappedData: CustomEntitiesSelectOption[] = item.map((itemEntity: any) => {
						return { name: itemEntity.name, value: itemEntity.id };
					});
					requestTypeFunc(mappedData);
				});
			});
	}, [customEntityDomainId]);

	const getRequestTypeFunction = (index: number) => {
		switch (index) {
			case 0:
				return setRoleSelect;
			case 1:
				return setOrganizationSelect;
			default:
				return setPlaceSelect;
		}
	};

	const updateTranslationsInRedux = (inputType: string, value: string) => {
		// const newTranslations = !areTranslationsEmpty ? [...translations] : [];
		// if (translationElIndex >= 0) {
		// 	newTranslations[translationElIndex] = { ...newTranslations[translationElIndex], [inputType]: value };
		// } else {
		// 	newTranslations.push({ language: selectedLanguage, name: '', short_name: '', three_letter_code: '', [inputType]: value });
		// }
		// const objectForUpdating = { translations: newTranslations, default_language: projectDefaultLanguage };
		// if (selectedLanguage === projectDefaultLanguage) {
		// 	// if we selected the default language, we need to update the custom entity object core value also
		// 	// ex: if we update the name in default language, we need to update the name in the custom entity object also (not only in the translations)
		// 	objectForUpdating[inputType] = value;
		// 	objectForUpdating.default_language = selectedLanguage;
		// }
		// updateCustomEntity(objectForUpdating);
	};

	console.log({ roleSelect, organizationSelect, placeSelect });
	return (
		<div id={DATA_QA.ROLES}>
			{/* <Row>
				<Col>
					<SelectInputDesign
						invokeFunc={(option) => setSelectedLanguage(option.value as string)}
						fieldId='language-select'
						labelText={t('translation_lang')}
						placeholderText={t('select')}
						options={languageOptions}
						selectedValue={selectedLanguage}
						disabled={!isMultilingualProject}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<InputDesign
						invokeFunc={(text) => updateTranslationsInRedux('name', text)}
						fieldId='name-input'
						labelText={t('name')}
						placeholderText={t('name')}
						value={translationElIndex >= 0 ? translations[translationElIndex].name : ''}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<InputDesign
						invokeFunc={(text) => updateTranslationsInRedux('short_name', text)}
						fieldId='short-name-input'
						labelText={t('short_name')}
						placeholderText={t('short_name')}
						value={translationElIndex >= 0 ? translations[translationElIndex].short_name : ''}
					/>
				</Col>
				<Col>
					<InputDesign
						invokeFunc={(text) => updateTranslationsInRedux('three_letter_code', text)}
						fieldId='three-letter-code-input'
						labelText={t('three_letter_code')}
						placeholderText={t('three_letter_code')}
						value={translationElIndex >= 0 ? translations[translationElIndex].three_letter_code : ''}
					/>
				</Col>
			</Row>
			{customEntity.entity_type === CustomEntitiesTypes.ORGANIZATION && (
				<Row>
					<Col>
						<InputDesign
							invokeFunc={(text) => updateTranslationsInRedux('address', text)}
							fieldId='address-input'
							labelText={t('address')}
							placeholderText={t('address')}
							value={translationElIndex >= 0 ? translations[translationElIndex].address : ''}
						/>
					</Col>
				</Row>
			)} */}
		</div>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		reduxRoles: state.customEntities[contentMode].roles || [],
		customEntityDomainId: state.customEntities[contentMode].contained_in_domain || '',
	};
}

function mapDispatchToProps(dispatch: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		updateCustomEntity: (dataForUpdate: Record<string, unknown>) => dispatch(updateCustomEntity(contentMode, dataForUpdate)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomEntityRoles);
