import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import SelectInputDesign from '../../../../Partials/design-components/select/select';
import InputDesign from '../../../../Partials/design-components/input/input';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DATA_QA } from '../../helpers/data-qa.properties';
import CustomEntityTranslations from './translations';
import CustomEntitySocials from './socials';
import { extractContentModeBasedOnUrl } from '../../../../../global-helpers/global.helpers';
import './details.scss';
import { ContentMode } from '../../../../../constants/content-types';
import CustomEntitiesHttpService from '../../../../../services/rest/custom-entities.http.service';
import { CustomEntitiesSelectOption } from '../../models/models';
import { updateCustomEntity } from '../../../../../store/action-creators/custom-entities-action-creator';
import ImagesUploads from './subcomponents/dnd-images';
import CustomEntityOptionals from './subcomponents/optional-fields';
import { customEntitiesTypeSelectOptions } from '../../helpers/custom-entities.helper';
import CustomEntityRoles from './subcomponents/roles';

type Props = {
	customEntity: any;
	isCreateMode: boolean;
	resetReduxData: () => void;
	updateCustomEntity: (dataForUpdate: Record<string, unknown>) => void;
};

const CustomEntityDetails: FunctionComponent<Props> = ({ customEntity, isCreateMode, updateCustomEntity }) => {
	const { t } = useTranslation();
	const [domains, setDomains] = useState<CustomEntitiesSelectOption[]>([]);

	useEffect(() => {
		CustomEntitiesHttpService.getDomains()
			.then((response) => response.data.results)
			.then((data) => {
				const mappedData: CustomEntitiesSelectOption[] = data.map((item: any) => {
					return { name: item.name, value: item.id };
				});

				setDomains(mappedData);
			});
	}, []);

	return (
		<div id={DATA_QA.CUSTOM_ENTITY_WRAPPER}>
			<h1>{isCreateMode ? t('create_new_entity') : t('edit_entity')}</h1>
			<span>{t('custom_entity_description')}</span>
			<Row>
				<Col>
					<SelectInputDesign
						invokeFunc={(option) => updateCustomEntity({ contained_in_domain: option.value })}
						fieldId='domain-select'
						labelText={t('domain')}
						placeholderText={t('select')}
						options={domains}
						selectedValue={customEntity.contained_in_domain}
					/>
				</Col>
				<Col>
					<SelectInputDesign
						invokeFunc={(option) => updateCustomEntity({ entity_type: option.value })}
						fieldId='entity-type-select'
						labelText={t('entity_type')}
						placeholderText={t('select')}
						options={customEntitiesTypeSelectOptions}
						selectedValue={customEntity.entity_type}
					/>
				</Col>
			</Row>
			<CustomEntityRoles />
			<CustomEntityTranslations />
			<Row>
				<Col>
					<InputDesign
						invokeFunc={(text) => updateCustomEntity({ slug: text })}
						fieldId='slug-input'
						labelText={t('slug')}
						placeholderText={t('slug')}
						value={customEntity.slug}
					/>
				</Col>
			</Row>
			<CustomEntityOptionals />
			{/* <ImagesUploads /> */}
			{/* <Row>
				<Col>
					<InputDesign invokeFunc={(text) => updateCustomEntity({ name: text })} fieldId='address-input' labelText={t('address')} />
				</Col>
			</Row> */}
			<CustomEntitySocials />
		</div>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		isCreateMode: contentMode === ContentMode.CREATE,
		customEntity: state.customEntities[contentMode] || {},
	};
}

function mapDispatchToProps(dispatch: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		updateCustomEntity: (dataForUpdate: Record<string, unknown>) => dispatch(updateCustomEntity(contentMode, dataForUpdate)),
		resetReduxData: () => dispatch(),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomEntityDetails);
