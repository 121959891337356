import { UPDATE_CUSTOM_ENTITY } from '../action-creators/custom-entities-action-creator';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import { CustomEntitiesSearchFiltersModel, Meta } from '../../views/Pages/CustomEntities/models/models';
import { CUSTOM_ENTITIES_FILTERS_UPDATE, CUSTOM_ENTITIES_PAGINATION_UPDATE } from '../action-creators/custom-entities-action-creator';

interface InitialState {
	pagination: Meta;
	filters: CustomEntitiesSearchFiltersModel;
	create: Record<string, any>;
	edit: Record<string, any>;
}

const initialState: InitialState = {
	pagination: { offset: 0 } as Meta,
	filters: {} as CustomEntitiesSearchFiltersModel,
	create: {},
	edit: {},
};

function customEntitiesReducer(state: any = initialState, action: any) {
	if (action.type === UPDATE_CUSTOM_ENTITY) {
		const { mode, dataForUpdate } = action.payload;

		console.log('UPDATE_CUSTOM_ENTITY', mode, dataForUpdate, state);
		return Object.assign({}, state, { ...state, [mode]: { ...state[mode], ...dataForUpdate } });
	}

	if (action.type === CUSTOM_ENTITIES_PAGINATION_UPDATE) {
		return Object.assign({}, state, {
			pagination: action.payload.pagination,
		});
	}

	if (action.type === CUSTOM_ENTITIES_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			filters: action.payload.filters,
			pagination: initialState.pagination,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default customEntitiesReducer;
