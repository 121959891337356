import React, { FunctionComponent } from 'react';
import { DebounceInput } from 'react-debounce-input';
import './input.scss';

type Props = {
	invokeFunc: (text: string) => void;
	fieldId: string;
	labelText?: string;
	placeholderText?: string;
	value?: string;
	invokeFuncTime?: number;
};

const InputDesign: FunctionComponent<Props> = ({ invokeFunc, fieldId, labelText, invokeFuncTime, placeholderText, value = '' }) => {
	const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => invokeFunc(event.target.value);

	return (
		<div className='custom-input-wrapper' id={fieldId}>
			{labelText && <label htmlFor={fieldId}> {labelText}</label>}
			<DebounceInput
				element='input'
				type='text'
				id={fieldId}
				className='custom-input'
				placeholder={placeholderText}
				debounceTimeout={invokeFuncTime || 0}
				onChange={onTextChange}
				value={value}
			/>
		</div>
	);
};

export default InputDesign;
