import React, { FunctionComponent, useEffect, useState } from 'react';
import { ContentTypes } from '../../../../../constants/content-types';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../../styles/custom-entities-listing.style.scss';
import { CustomEntitiesListingModel, CustomEntitiesSearchFiltersModel, CustomEntityBasic, Meta } from '../../models/models';
import { useTranslation } from 'react-i18next';
import { CustomEntitiesButtonCreate } from './sub-components/custom-entities-button-create';
import {
	customEntitiesPaginateProps,
	CustomEntitiesTypes,
	extractCustomEntitiesSearchQueryFromFilters,
	LIMIT,
	notDeletable,
	notEditableAndDeletable,
} from '../../helpers/custom-entities.helper';
import { CustomEntitySearchComponent } from './sub-components/custom-entities-search.component';
import ReactPaginate from 'react-paginate';
import { CustomEntityModalDelete } from './sub-components/custom-entity-delete.modal';
import { toast } from 'react-toastify';
import CustomEntitiesHttpService from '../../../../../services/rest/custom-entities.http.service';
import { CustomEntitiesAdvancedContentFilter } from './sub-components/custom-entities-advanced-search-filters.component';
import { getDeleteIconAsCssUrl, getEditIconAsCssUrl } from '../../../../../global-helpers/global-icons.helpers';
import { DATA_QA } from '../../helpers/data-qa.properties';

type Properties = {
	content: CustomEntitiesListingModel;
	contentPagination: Meta;
	filters: CustomEntitiesSearchFiltersModel;
	getContent: (searchQuery: string, offset: number, limit: number) => void;
	updateContentFilters: (filters: CustomEntitiesSearchFiltersModel) => void;
	updateContentPagination: (pagination: Meta) => void;
	setCurrentOffset: (offset: number) => void;
	currentOffset: number;
};

export const CustomEntitiesTableListing: FunctionComponent<Properties> = ({
	content,
	filters,
	contentPagination,
	getContent,
	updateContentFilters,
	updateContentPagination,
	setCurrentOffset,
	currentOffset,
}) => {
	const searchQueryFromFilters = extractCustomEntitiesSearchQueryFromFilters(filters);

	const [t] = useTranslation();
	const [selectedRowForDelete, setSelectedRowForDelete] = useState<CustomEntityBasic>({} as CustomEntityBasic);
	const [contentSearchInput, setContentSearchInput] = useState(filters && filters.search_text ? filters.search_text : '');
	const [advancedContentSearchInput, setAdvancedContentSearchInput] = useState('');
	const [toggleFilters, setToggleAdvancedFilters] = useState(
		filters &&
			((filters.domain && filters.domain.id !== '') || (filters.entity_types && filters.entity_types.length > 0)) &&
			searchQueryFromFilters !== '',
	);

	useEffect(() => {
		getContent(searchQueryFromFilters, contentPagination.offset, LIMIT);
	}, [filters.domain, filters.entity_types]);

	const onPageClick = (data: Record<string, number>) => {
		const selectedPage = data.selected * LIMIT;
		setCurrentOffset(selectedPage);
		updateContentPagination({ ...contentPagination, offset: selectedPage });
		getContent(searchQueryFromFilters, selectedPage, LIMIT);
	};

	const updateInputState = (text: string) => {
		updateFilters(text);
		setContentSearchInput(text);
	};

	const updateAdvancedInputState = (text: string) => {
		updateFilters(contentSearchInput);
		setAdvancedContentSearchInput(text);
	};

	const updateFilters = (searchText: string) => {
		const mainFilter = filters;
		if (mainFilter) {
			mainFilter.search_text = searchText;
		}

		updateContentFilters(mainFilter);
	};

	const deleteCustomEntity = () => {
		const id = selectedRowForDelete && selectedRowForDelete.id ? selectedRowForDelete.id : '';

		if (id && id.length > 0) {
			return CustomEntitiesHttpService.deleteCustomEntity(id, selectedRowForDelete.entity_type)
				.then(() => {
					toast.success(t('custom_entity_delete_success'));
					return getContent(searchQueryFromFilters, currentOffset, LIMIT);
				})
				.catch(() => {
					toast.error(t('custom_entity_delete_error'));
				});
		}

		return null;
	};

	const toggleAdvancedFilters = () => {
		setToggleAdvancedFilters(!toggleFilters);
	};

	return (
		<div className='card-body'>
			<div className='content-list-header'>
				<div className='table-header'>
					<Col className='d-flex flex-row mb-2 justify-content-between'>
						<CustomEntitiesButtonCreate />
						<CustomEntitySearchComponent
							updateSearchText={updateInputState}
							currentOffset={(contentPagination && contentPagination.offset) || 0}
							getContent={getContent}
							contentSearchInput={contentSearchInput}
							advancedContentSearchInput={searchQueryFromFilters}
							setToggle={toggleAdvancedFilters}
							setCurrentOffset={setCurrentOffset}
						/>
					</Col>
				</div>
				<div className='advance-filters-header'>
					<Col className='ml-auto'>
						{toggleFilters && (
							<CustomEntitiesAdvancedContentFilter
								contentSearchInput={contentSearchInput}
								updateCustomEntitiesAdvancedSearchText={updateAdvancedInputState}
							/>
						)}
					</Col>
				</div>
			</div>
			<table className='table table-striped content-list-table' data-qa={`table-${ContentTypes.CUSTOM_ENTITIES}`}>
				<thead className='bg-dark'>
					<tr className='table-upper-part'>
						<th className='w-title col-md-3 col-sm-5 table-upper-part'>{t('entity')}</th>
						<th className='col-md-2 table-upper-part'>{t('domain')}</th>
						<th className='col-md-3 table-upper-part'>{t('entity_type')}</th>
						<th className='col-md-1 col-sm-3 table-upper-part'>{t('actions')}</th>
					</tr>
				</thead>
				<tbody>
					{content && content.results && content.results.length > 0 ? (
						content.results.map((entity: CustomEntityBasic) => {
							const deleteBtnStyle = entity.entity_type === CustomEntitiesTypes.ROLE ? 'role-delete-btn' : '';
							return (
								<tr key={`${ContentTypes.CUSTOM_ENTITIES}-${entity.id}`} data-qa={DATA_QA.CUSTOM_ENTITIES_LISTING_ITEM}>
									<td className='w-title col-md-3 col-sm-5 align-middle'>
										{!notEditableAndDeletable(entity.entity_type) ? (
											<Link to={`/smp/custom-entities/edit/${entity.entity_type}/${entity.id}`}>
												<span className={`pl-1 text-break table-text`}>
													<span className='table-text'>{entity.name}</span>
												</span>
											</Link>
										) : (
											<>
												<span className={`pl-1 text-break table-text`}>
													<span className='table-text'>{entity.name}</span>
												</span>
											</>
										)}
									</td>
									<td className={`col-md-2 align-middle table-text`}>
										<span className='table-text'>{entity.contained_in_domain ? entity.contained_in_domain.name : ''}</span>
									</td>
									<td className={`d-md-table-cell col-md-3 align-middle table-text`}>
										<span className='table-text'>{entity.entity_type}</span>
									</td>
									<td className='col-md-1 col-sm-3'>
										<div className='d-flex flex-wrap custom-entities-btns'>
											{!notEditableAndDeletable(entity.entity_type) && (
												<Link to={`/smp/custom-entities/edit/${entity.entity_type}/${entity.id}`}>
													<div
														data-qa={DATA_QA.CUSTOM_ENTITIES_EDIT_BUTTON}
														className='custom-entities-btn'
														style={{ backgroundImage: getEditIconAsCssUrl() }}
													/>
												</Link>
											)}
											{!notDeletable(entity.entity_type) && (
												<div
													data-qa={DATA_QA.CUSTOM_ENTITIES_DELETE_BUTTON}
													className={`custom-entities-btn ${deleteBtnStyle}`}
													style={{ backgroundImage: getDeleteIconAsCssUrl() }}
													onClick={() => setSelectedRowForDelete(entity)}
												/>
											)}
										</div>
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<h6 className='d-flex justify-content-center mt-6 mb-4'>
								<span className='header-text mt-4 pad2x pad2y'>{t('no_data_found')}</span>
							</h6>
						</tr>
					)}
				</tbody>
			</table>
			{content && content.meta && (
				<div data-qa={DATA_QA.CUSTOM_ENTITIES_PAGINATION}>
					<ReactPaginate {...customEntitiesPaginateProps(content.meta, onPageClick)} />
				</div>
			)}
			<CustomEntityModalDelete
				selectedRowForDelete={selectedRowForDelete}
				setSelectedRowForDelete={setSelectedRowForDelete}
				deleteContent={deleteCustomEntity}
			/>
		</div>
	);
};
