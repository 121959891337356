import React, { FunctionComponent, useState } from 'react';
import './select.scss';

type SelectOption = { name: string; value: string | number };

type Props = {
	invokeFunc: (option: SelectOption) => void;
	fieldId: string;
	labelText?: string;
	placeholderText?: string;
	options: SelectOption[];
	selectedValue?: string | number;
	disabled?: boolean;
};

const SelectInputDesign: FunctionComponent<Props> = ({
	invokeFunc,
	fieldId,
	labelText,
	placeholderText,
	options,
	selectedValue,
	disabled,
}) => {
	const [toggleOptions, setToggleOptions] = useState<boolean>(false);
	const selectedOption = options.find((option) => option.value === selectedValue);
	const toggleOptionsVisibility = () => !disabled && setToggleOptions(!toggleOptions);

	const onSelect = (option: SelectOption) => {
		invokeFunc(option);
		toggleOptionsVisibility();
	};

	return (
		<div className='custom-select-input-wrapper' id={fieldId}>
			{labelText && <label htmlFor={fieldId}> {labelText}</label>}
			<div id={fieldId} className='custom-select-value-holder' onClick={toggleOptionsVisibility}>
				{selectedOption && selectedOption.name ? selectedOption.name : placeholderText}
				<div className='select-icon'></div>
			</div>
			<div className='custom-select-options' style={{ display: toggleOptions ? 'block' : 'none' }}>
				{options.map((option) => (
					<div className='custom-select-option' key={option.value} onClick={() => onSelect(option)} title={option.name}>
						{option.name}
					</div>
				))}
			</div>
		</div>
	);
};

export default SelectInputDesign;
