import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { extractContentModeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { updateCustomEntity } from '../../../../../../store/action-creators/custom-entities-action-creator';
import { FieldTypeData, getOptionalField, getOptionalFieldsData } from '../../../helpers/custom-entities.helper';

type Props = {
	customEntity: Record<string, string>;
	updateCustomEntity: (dataForUpdate: Record<string, unknown>) => void;
};

const CustomEntityOptionals: FunctionComponent<Props> = ({ customEntity, updateCustomEntity }) => {
	const optionalFieldsData = getOptionalFieldsData(customEntity.entity_type);

	const updateOptionalFieldInRedux = (propertyName: string, value: string) => updateCustomEntity({ [propertyName]: value });

	return (
		<div id={DATA_QA.OPTIONAL_FIELDS}>
			<Row>
				{optionalFieldsData.map((optData: FieldTypeData) => {
					return (
						<Col md='6' key={optData.slug}>
							{getOptionalField(optData, customEntity[optData.slug], updateOptionalFieldInRedux)}
						</Col>
					);
				})}
			</Row>
		</div>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		customEntity: state.customEntities[contentMode] || {},
	};
}

function mapDispatchToProps(dispatch: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		updateCustomEntity: (dataForUpdate: Record<string, unknown>) => dispatch(updateCustomEntity(contentMode, dataForUpdate)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomEntityOptionals);
