import React from 'react';
import { ReactPaginateProps } from 'react-paginate';
import { PAGINATE_PROPS } from '../../../Partials/BaseComponents/ListingComponent/subcomponents/AuthorizedListing/properties/PaginateProps';
import { CustomEntitiesSearchFiltersModel, Domain, Meta } from '../models/models';
import i18n from 'i18next';
import InputDesign from '../../../Partials/design-components/input/input';
import SelectInputDesign from '../../../Partials/design-components/select/select';
import '../styles/custom-entities-listing.style.scss';
import StylesConfig from 'react-select';
import { MultiValueGenericProps } from 'react-select/src/components/MultiValue';

export const LIMIT = 20;

export const returnListingQueryParameter = (parameters: string[]) => {
	let queryParameters: string[] = [];

	if (parameters && parameters.length > 0) {
		queryParameters = parameters.map((parameter: string) => parameter);
	}

	return queryParameters;
};

export const CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS = () => [
	{ value: 'organization', label: 'organization', data: 'organization' },
	{ value: 'place', label: 'place', data: 'place' },
	{ value: 'person', label: 'person', data: 'person' },
	{ value: 'role', label: 'role', data: 'role' },
	{ value: 'domain', label: 'domain', data: 'domain' },
];

export const CUSTOM_ENTITIES_ENTITY_TYPES_DEFAULT_OPTIONS = ['organization', 'place', 'person', 'role', 'domain'];

export const selectEntityTypesOptions = (values: string[]) => {
	return (
		values &&
		values.map((value: string) => {
			switch (value) {
				case CustomEntitiesTypes.ORGANIZATION: {
					return CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS()[0];
				}
				case CustomEntitiesTypes.PLACE: {
					return CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS()[1];
				}
				case CustomEntitiesTypes.PERSON: {
					return CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS()[2];
				}
				case CustomEntitiesTypes.ROLE: {
					return CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS()[3];
				}
				case CustomEntitiesTypes.DOMAIN: {
					return CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS()[4];
				}
				default: {
					return null;
				}
			}
		})
	);
};

export const customEntitiesPaginateProps = (
	contentPagination: Meta,
	handlePageClick: (selectedItem: { selected: number }) => void,
): ReactPaginateProps => {
	const totalPages = Math.ceil(contentPagination.total / LIMIT);

	return {
		...PAGINATE_PROPS,
		previousLabel: i18n.t('previous_page'),
		nextLabel: i18n.t('next_page'),
		pageCount: totalPages,
		onPageChange: handlePageClick,
		marginPagesDisplayed: 2,
		pageRangeDisplayed: 1,
		containerClassName: 'custom-entities-pagination',
		forcePage: contentPagination.offset / 20,
	};
};

export const constructCustomEntitiesSearchContentQuery = (data: CustomEntitiesSearchFiltersModel) => {
	if (data) {
		const entityTypeQuery =
			data.entity_types && data.entity_types.length > 0 ? `&entity_type=${returnListingQueryParameter(data.entity_types)}` : '';
		const domainQuery = data.domain && data.domain.id && data.domain.id.length > 0 ? `&domain=${data.domain.slug}` : '';

		return entityTypeQuery + domainQuery;
	}

	return '';
};

export const checkIfCustomEntitiesFiltersAreEmpty = (model: CustomEntitiesSearchFiltersModel) => {
	if (model && Object.entries(model).length > 0) {
		const isEntityTypesEmpty = !model.entity_types || (model.entity_types && model.entity_types.length === 0);
		const isDomainsEmpty = !model.domain || (model.domain && model.domain.id && model.domain.id.length === 0);

		return isEntityTypesEmpty && isDomainsEmpty;
	}

	return true;
};

export const extractCustomEntitiesSearchQueryFromFilters = (filters: CustomEntitiesSearchFiltersModel) => {
	let query: string = '';

	if (filters && Object.entries(filters).length > 0) {
		const searchText = filters.search_text && filters.search_text.length > 0 ? `name=${filters.search_text}` : '';
		const advancedContentSearchText = constructCustomEntitiesSearchContentQuery(filters);

		query = searchText + advancedContentSearchText;
	}

	return query;
};

export const domainsToOptions = (domains: Domain[]) => {
	if (domains && domains.length > 0) {
		return domains.map((domain) => {
			return domainToOption(domain);
		});
	}
	return [];
};

export const domainToOption = (domain: Domain | null) => {
	if (domain) {
		return {
			value: domain.id,
			label: domain.name,
			data: domain,
			type: 'domain',
		};
	} else {
		return null;
	}
};

enum InputFieldType {
	TEXT = 'text',
	NUMBER = 'number',
	DATE = 'date',
	SELECT = 'select',
}

export type FieldTypeData = {
	slug: string;
	name: string;
	type: InputFieldType;
};

const generateFieldType = (slug: string, translationName: string, inputType: InputFieldType = InputFieldType.TEXT) => {
	return {
		slug,
		name: i18n.t(translationName),
		type: inputType,
	} as FieldTypeData;
};

const optionalFields = {
	person: [
		generateFieldType('birthday', 'birthday', InputFieldType.DATE),
		generateFieldType('gender', 'gender', InputFieldType.SELECT),
		generateFieldType('birth_place', 'birth_place', InputFieldType.TEXT),
		generateFieldType('nationality', 'nationality', InputFieldType.TEXT),
		generateFieldType('height', 'height', InputFieldType.NUMBER),
		generateFieldType('weight', 'weight', InputFieldType.NUMBER),
		generateFieldType('website', 'website', InputFieldType.TEXT),
		generateFieldType('email', 'email', InputFieldType.TEXT),
	],
	organization: [
		generateFieldType('founding_date', 'founding_date', InputFieldType.DATE),
		generateFieldType('founding_year', 'founding_year', InputFieldType.DATE),
		generateFieldType('website', 'website', InputFieldType.TEXT),
		generateFieldType('email', 'email', InputFieldType.TEXT),
	],
	place: [generateFieldType('website', 'website', InputFieldType.TEXT), generateFieldType('email', 'email', InputFieldType.TEXT)],
};

export const getOptionalFieldsData = (entityType: string) => {
	if (entityType) {
		return optionalFields[entityType] || [];
	}

	return [];
};

export const notEditableAndDeletable = (entityType: string) => {
	return ['role', 'domain'].includes(entityType);
};

export const notDeletable = (entityType: string) => {
	return ['domain'].includes(entityType);
};

export enum CustomEntitiesTypes {
	ORGANIZATION = 'organization',
	PLACE = 'place',
	PERSON = 'person',
	ROLE = 'role',
	DOMAIN = 'domain',
}

export const getOptionalField = (optData: FieldTypeData, value: string, updateFunc: Function) => {
	const { name, slug, type } = optData;

	switch (type) {
		case InputFieldType.TEXT:
		case InputFieldType.NUMBER:
			return (
				<InputDesign
					invokeFunc={(text: string) => updateFunc(slug, text)}
					fieldId={slug}
					labelText={name}
					placeholderText={name}
					value={value}
				/>
			);
		case InputFieldType.SELECT:
			return (
				<SelectInputDesign
					invokeFunc={(option) => updateFunc(slug, option.value)}
					fieldId={slug}
					labelText={name}
					placeholderText={name}
					options={[
						{ name: 'Male', value: 'male' },
						{ name: 'Female', value: 'female' },
						{ name: 'Other', value: 'other' },
					]}
					selectedValue={value}
				/>
			);
		case InputFieldType.DATE:
			return (
				<InputDesign
					invokeFunc={(date: string) => updateFunc(slug, date)}
					fieldId={slug}
					labelText={name}
					placeholderText={name}
					value={value}
				/>
			);
	}
};

interface OptionType {
	value: string;
	label: string;
}

export const customEntitiesTypeSelectOptions = [
	{ name: 'Person', value: CustomEntitiesTypes.PERSON },
	{ name: 'Organization', value: CustomEntitiesTypes.ORGANIZATION },
	{ name: 'Place', value: CustomEntitiesTypes.PLACE },
	{ name: 'Role', value: CustomEntitiesTypes.ROLE },
];

export const customEntitiesAdvancedFiltersStyles = {
	control: (provided: StylesConfig, state: MultiValueGenericProps<OptionType>) => ({
		...provided,
		backgroundColor: '#FFFFFF',
		border: state.selectProps.menuIsOpen ? '1px solid #1058E1' : '1px solid #D9D9D9',
		borderRadius: '6px',
		marginBottom: '20px',
		width: '300px',
		justifyContent: 'space-between',
		boxShadow: state.selectProps.menuIsOpen ? '0 0 0 1px #1058E1' : 'none',
		'&:hover': {
			borderColor: '#1058E1',
		},
	}),
	indicatorSeparator: (provided: StylesConfig) => ({
		...provided,
		display: 'none',
		color: '#666666',
	}),
	dropdownIndicator: (provided: StylesConfig, state: MultiValueGenericProps<OptionType>) => ({
		...provided,
		color: state.selectProps.menuIsOpen ? '#1058E1' : '#666666',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
		transition: 'transform 0.2s ease-out',
		'&:hover': {
			color: '#1058E1',
		},
	}),
	clearIndicator: (provided: StylesConfig, state: MultiValueGenericProps<OptionType>) => ({
		...provided,
		color: state.selectProps.menuIsOpen ? '#1058E1' : '#666666',
		'&:hover': {
			color: '#1058E1',
		},
	}),
	multiValue: (provided: StylesConfig) => ({
		...provided,
		borderRadius: '6px',
		padding: '2px 4px 2px 4px',
	}),
	multiValueLabel: (provided: StylesConfig) => ({
		...provided,
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
	}),
	multiValueRemove: (provided: StylesConfig) => ({
		...provided,
	}),
	singleValue: (provided: StylesConfig) => ({
		...provided,
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
	}),
	menuList: (provided: StylesConfig) => ({
		...provided,
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
	}),
	placeholder: (provided: StylesConfig) => ({
		...provided,
		color: 'grey',
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
	}),
};
