import axios from 'axios';
import { multiLingualService } from '../../App';
import { store } from '../../store/store';
import REACT_APP_URLS from '../../global-helpers/global-url.helpers';
import {
	CUSTOM_ENTITIES_ENTITY_TYPES_DEFAULT_OPTIONS,
	CustomEntitiesTypes,
	returnListingQueryParameter,
} from '../../views/Pages/CustomEntities/helpers/custom-entities.helper';

const baseURL = REACT_APP_URLS.REACT_APP_SEARCH_API_V2_URL;

export default class CustomEntitiesHttpService {
	static getProjectLanguage = (): string => {
		const reduxState = store.getState();
		return reduxState.project && reduxState.project.currentProject && reduxState.project.currentProject.language
			? reduxState.project.currentProject.language
			: 'en';
	};

	static getGeneralInstanceHeaders = () => {
		return {
			'X-Project': `${store.getState().project.currentProject.domain}`,
			'Accept-Language': `${
				multiLingualService.checkIfProjectIsMultiLingual(store.getState().project.currentProject.languages) &&
				multiLingualService.checkRoutePath()
					? multiLingualService.setCorrectAcceptLanguageCode(store.getState().project.currentProject.languages.defaultLanguageCode.languageCode)
					: store.getState().project.currentProject.language
					? store.getState().project.currentProject.language.split('-')[0]
					: 'en'
			}`,
			'Content-Type': 'application/json',
		};
	};

	static instance = () => {
		return axios.create({
			baseURL,
			timeout: 30000,
			headers: {
				...CustomEntitiesHttpService.getGeneralInstanceHeaders(),
				Authorization: 'Basic c2VhcmNoYXBpOkdVWmlZRk02eU4=',
			},
		});
	};

	static getCustomEntities = (searchQuery: string, offset: number, limit: number) => {
		if (!searchQuery.includes('entity_type')) {
			return CustomEntitiesHttpService.instance().get(
				`/suggest?${searchQuery}&entity_type=${CUSTOM_ENTITIES_ENTITY_TYPES_DEFAULT_OPTIONS}&offset=${offset}&limit=${limit}&input_language=${CustomEntitiesHttpService.getProjectLanguage()}`,
			);
		} else {
			return CustomEntitiesHttpService.instance().get(
				`/suggest?${searchQuery}&offset=${offset}&limit=${limit}&input_language=${CustomEntitiesHttpService.getProjectLanguage()}`,
			);
		}
	};

	static deleteCustomEntity = (id: string, type: string) => {
		return CustomEntitiesHttpService.instance().delete(`/${type}/${id}`);
	};

	static getDomains = () => {
		return CustomEntitiesHttpService.instance().get(`/suggest?entity_type=${CustomEntitiesTypes.DOMAIN}`);
	};

	static getRoles = () => {
		return CustomEntitiesHttpService.instance().get(`/suggest?entity_type=${CustomEntitiesTypes.ROLE}`);
	};

	static getOrganizations = (domain: string) => {
		return CustomEntitiesHttpService.instance().get(`/suggest?domain=${domain}&entity_type=${CustomEntitiesTypes.ORGANIZATION}`);
	};

	static getPlaces = (domain: string) => {
		return CustomEntitiesHttpService.instance().get(`/suggest?domain=${domain}&entity_type=${CustomEntitiesTypes.PLACE}`);
	};
}
