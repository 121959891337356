import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { analyticsService } from '../../../../../../App';
import { useTranslation } from 'react-i18next';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import '../../../styles/custom-entities-listing.style.scss';
import { getAddIconAsCssUrl } from '../../../../../../global-helpers/global-icons.helpers';

export const CustomEntitiesButtonCreate: FunctionComponent<any> = () => {
	const [t] = useTranslation();
	return (
		<div data-qa={DATA_QA.CUSTOM_ENTITIES_CREATE_BUTTON}>
			<Link
				to={'/smp/custom-entities/create'}
				id='new-custom-entity'
				className='btn custom-entity-btn-create-new'
				onClick={() => analyticsService.sendEvent('Click', 'List Page', 'Create custom entity')}
			>
				<div className='create-btn-icon' style={{ backgroundImage: getAddIconAsCssUrl() }} />
				<span className='btn-text'>
					<strong>{t('create_new_entity')}</strong>
				</span>
			</Link>
		</div>
	);
};
